// in ./ResetViewsButton.js
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  useNotify,
  useUnselectAll,
  fetchStart,
  fetchEnd,
} from "react-admin";
import { Refresh } from "@material-ui/icons";
import { refreshRestaurant } from "../../service/refreshRestaurant.service";

const RefreshRestaurantButton = ({ data }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    refreshRestaurant([data.id])
      .then(({ status }) => {
        if (status === 200) {
          notify(
            "Restaurants pending refresh, wait 1 min!",
            "info",
            {},
            false,
            5000
          );
          unselectAll("restaurant");
        } else {
          notify(
            "Error: Restaurants not refreshed",
            "warning",
            {},
            false,
            8000
          );
        }
      })
      .catch((e) => {
        notify("Error: Restaurants not refreshed", "warning", {}, false, 8000);
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <Button
      label="Refresh restaurant google data"
      disabled={loading}
      onClick={handleClick}
    >
      <Refresh />
    </Button>
  );
};

export default RefreshRestaurantButton;
