// in ./ResetViewsButton.js
import * as React from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import { ThumbUp, ThumbDown } from "@material-ui/icons";

const ValidatePropositionButton = ({ selectedIds, validated = true }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "restaurantProposition",
    selectedIds,
    { validated, done: false },
    {
      onSuccess: () => {
        refresh();
        notify(`Propositions ${validated ? "validated" : "invalidated"}`);
        unselectAll("restaurantProposition");
      },
      onFailure: (error) =>
        notify("Error: propositions not updated", "warning"),
    }
  );

  const buttonLabel = `${validated ? "Validate" : "Invalidate"} propositions`;

  return (
    <Button label={buttonLabel} disabled={loading} onClick={updateMany}>
      {validated ? <ThumbUp /> : <ThumbDown />}
    </Button>
  );
};

export default ValidatePropositionButton;
