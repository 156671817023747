import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { getResourcePath } from "../utils/getResourcePath.util";

const dataProvider = (apiUrl) => {
  const httpClient = (url, options) => {
    options = options || {};
    options.headers = new Headers({ Accept: "application/json" });
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      options.headers.set("admin-api-key", `${apiKey}`);
    }

    return fetchUtils.fetchJson(url, options);
  };
  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      console.log("perPage", perPage);
      const newPerPage = perPage === 1000 ? 10000 : perPage;
      const query = {
        sortField: field,
        sortOrder: order,
        offset: (page - 1) * newPerPage,
        limit: newPerPage,
      };

      if (Object.entries(params.filter).length > 0) {
        const keys = Object.keys(params.filter);
        query.searchFilter = JSON.stringify(
          keys.map((key) => {
            return {
              key,
              value: params.filter[key],
            };
          })
        );
      }
      const url = `${apiUrl}/${getResourcePath(resource)}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        const total = headers.get("Content-Range") ? parseInt(headers.get("Content-Range"), 10) : 0;
        return {
          data: json,
          total,
      }});
    },

    getOne: (resource, params) =>
      httpClient(`${apiUrl}/${getResourcePath(resource)}/${params.id}`).then(
        ({ json }) => ({
          data: json,
        })
      ),

    getMany: (resource, params) => {
      const query = {
        manyFilter: JSON.stringify({ ids: params.ids }),
      };
      const url = `${apiUrl}/${getResourcePath(resource)}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => {
        return { data: json };
      });
    },

    getManyReference: (resource, params) => {
      const { target, id } = params;
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sortField: field,
        sortOrder: order,
        offset: (page - 1) * perPage,
        limit: perPage,
        target,
        id,
      };

      if (Object.entries(params.filter).length > 0) {
        const keys = Object.keys(params.filter);
        query.searchFilter = JSON.stringify(
          keys.map((key) => {
            return {
              key,
              value: params.filter[key],
            };
          })
        );
      }

      const url = `${apiUrl}/${getResourcePath(resource)}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        const total = headers.get("content-range") ? parseInt(headers.get("content-range"), 10) : 0;
        return {
          data: json,
          total,
      }});
    },

    update: (resource, params) => {
      const body = {
        entity: params.data,
      };
      const query = {
        manyFilter: JSON.stringify({ ids: [params.id] }),
        updateMany: false,
      };
      return httpClient(
        `${apiUrl}/${getResourcePath(resource)}?${stringify(query)}`,
        {
          method: "PUT",
          body: JSON.stringify(body),
        }
      ).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
      const body = {
        entity: params.data,
      };
      const query = {
        manyFilter: JSON.stringify({ ids: params.ids }),
        updateMany: true,
      };
      return httpClient(
        `${apiUrl}/${getResourcePath(resource)}?${stringify(query)}`,
        {
          method: "PUT",
          body: JSON.stringify(body),
        }
      ).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
      const body = {
        entity: params.data,
      };

      return httpClient(`${apiUrl}/${getResourcePath(resource)}/new`, {
        method: "PUT",
        body: JSON.stringify(body),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    },

    delete: (resource, params) => {
      const query = {
        manyFilter: JSON.stringify({ ids: [params.id] }),
      };

      return httpClient(
        `${apiUrl}/${getResourcePath(resource)}?${stringify(query)}`,
        {
          method: "DELETE",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({ data: json }));
    },

    deleteMany: (resource, params) => {
      const query = {
        manyFilter: JSON.stringify({ ids: params.ids }),
      };

      return httpClient(
        `${apiUrl}/${getResourcePath(resource)}?${stringify(query)}`,
        {
          method: "DELETE",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({ data: json }));
    },
  };
};

export default dataProvider;
