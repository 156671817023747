import * as React from "react";
import { Edit, NumberInput, SimpleForm } from "react-admin";

const ModeratorEdit = ({ permissions, ...props }) => (
  <Edit title="Edit Moderator" {...props}>
    <SimpleForm warnWhenUnsavedChanges redirect="show">
      <NumberInput source="weight" />
    </SimpleForm>
  </Edit>
);

export default ModeratorEdit;
