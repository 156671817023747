/* eslint react/jsx-key: off */
import PeopleIcon from "@material-ui/icons/People";
import memoize from "lodash/memoize";
import * as React from "react";
import { Fragment } from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  TextField,
  Filter,
  SearchInput,
  BulkExportButton,
} from "react-admin";

export const UserIcon = PeopleIcon;

const CustomDescriptionField = ({ record }) => {
  // "record" is a prop received from the Datagrid
  const str = record.description;
  return str ? (
    <span>{str.length > 200 ? str.slice(0, 200) + "..." : str}</span>
  ) : null;
};

const TagBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteWithConfirmButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
);

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const TagFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
);

const TagList = ({ ...props }) => (
  <List
    {...props}
    filters={<TagFilter />}
    bulkActionButtons={<TagBulkActionButtons />}
  >
    <Datagrid
      rowClick={rowClick()}
      // expand={<TagEditEmbedded />}
      optimized
    >
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="emoji" />
      <CustomDescriptionField />
    </Datagrid>
  </List>
);

export default TagList;
