import { fetchUtils } from "react-admin";
import { apiUrl } from "../App";

export const changeSlug = async (values) => {
  const httpClient = (url, options) => {
    options = options || {};
    options.headers = new Headers({
      Accept: "application/json",
    });
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      options.headers.set("admin-api-key", `${apiKey}`);
    }

    return fetchUtils.fetchJson(url, options);
  };

  return httpClient(`${apiUrl}/restaurant/${values.restaurantId}/slug`, {
    method: "PUT",
    body: JSON.stringify({
      slug: values.slug,
    }),
  });
};
