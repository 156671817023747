import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  required,
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  ImageInput,
  ImageField,
  fetchStart,
  fetchEnd,
  BooleanInput,
  useRefresh,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { addPicture } from "../service/addPicture.service";

function PictureQuickCreateButton({ onChange, record }) {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    values.restaurantId = record.id;

    await addPicture(values)
      .then(({ data }) => {
        setShowDialog(false);
        refresh();
      })
      .catch((e) => {
        notify("Error: Picture not created", "warning", {}, false, 8000);
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create picture"
      >
        <DialogTitle>Create picture</DialogTitle>

        <FormWithRedirect
          resource="picture"
          save={handleSubmit}
          initialValues={{ principal: false }}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ImageInput source="picture" validate={required()}>
                  <ImageField source="src" title="title" />
                </ImageInput>
                <BooleanInput source="principal" />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default PictureQuickCreateButton;
