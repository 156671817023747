import * as React from "react";
import {
  Edit,
  FormTab,
  TabbedForm,
  BooleanInput,
  NullableBooleanInput,
  TextField,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

const RestaurantEdit = ({ permissions, ...props }) => (
  <Edit title="Edit Restaurant Proposition" {...props}>
    <TabbedForm warnWhenUnsavedChanges>
      <FormTab label="informations">
        <TextField source="name" />
        <BooleanInput source="validated" />
        <NullableBooleanInput source="restaurantData.alcohol" label="Alcool" />
        <TextInput
          source="restaurantData.customDeliveryLink"
          label="Custom delivery link"
        />
        <TextInput
          source="restaurantData.deliverooLink"
          label="Deliveroo link"
        />
        <TextInput source="restaurantData.justEatLink" label="JustEat link" />
        <TextInput source="restaurantData.uberEatsLink" label="UberEats link" />
        <TextInput source="restaurantData.facebookLink" label="Facebook link" />
        <TextInput
          source="restaurantData.instagramLink"
          label="Instagram link"
        />
        <ArrayInput disabled source="restaurantData.tags" label="Tags">
          <SimpleFormIterator disableAdd disableRemove>
            <TextInput source="name" disabled />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default RestaurantEdit;
