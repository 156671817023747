import * as React from "react";
import {
  TopToolbar,
  Edit,
  CloneButton,
  ShowButton,
  SimpleForm,
  TextInput,
  BooleanInput,
  NullableBooleanInput,
} from "react-admin";

import { Divider, Typography } from "@material-ui/core";

const EditActions = ({ basePath, data, hasShow }) => (
  <TopToolbar>
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    {hasShow && <ShowButton basePath={basePath} record={data} />}
  </TopToolbar>
);

const RestaurantEdit = ({ permissions, ...props }) => (
  <Edit title="Edit Restaurant" actions={<EditActions />} {...props}>
    <SimpleForm warnWhenUnsavedChanges redirect="show">
      <Typography variant="h6" gutterBottom>
        Info de base
      </Typography>
      <TextInput disabled source="id" />
      <TextInput source="name" disabled />
      <TextInput source="customName" />
      <TextInput source="emailOwner" />
      <TextInput source="promo" />
      <Divider />
      <Typography variant="h6" gutterBottom>
        Gestion des toggles
      </Typography>
      <BooleanInput source="active" />
      <NullableBooleanInput source="alcohol" />
      <BooleanInput source="halal" />
      <NullableBooleanInput source="websiteSubscribed" />
      <Divider />
      <Typography variant="h6" gutterBottom>
        Liens du restaurant
      </Typography>
      <TextInput source="customDeliveryLink" />
      <TextInput source="withWebsite" />
      <TextInput source="uberEatsLink" />
      <TextInput source="deliverooLink" />
      <TextInput source="justEatLink" />
      <TextInput source="instagramLink" />
      <TextInput source="facebookLink" />
    </SimpleForm>
  </Edit>
);

export default RestaurantEdit;
