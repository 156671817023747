import MonetizationOn from "@material-ui/icons/MonetizationOn";
import advertisementList from "./advertisementList";
import advertisementShow from "./advertisementShow";

const advertisement = {
  list: advertisementList,
  show: advertisementShow,
  icon: MonetizationOn,
};

export default advertisement;
