import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import ModeratorEdit from "./moderatorEdit";
import ModeratorList from "./moderatorList";
import ModeratorShow from "./moderatorShow";

const advertisement = {
  list: ModeratorList,
  show: ModeratorShow,
  edit: ModeratorEdit,
  icon: SupervisorAccount,
};

export default advertisement;
