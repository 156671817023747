import * as React from "react";
import memoize from "lodash/memoize";
import {
  Tab,
  TabbedShowLayout,
  Show,
  EmailField,
  NumberField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ReferenceField,
  TextField,
  TopToolbar,
  EditButton,
} from "react-admin";

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const ModeratorShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ModeratorShow = (props) => {
  return (
    <Show {...props} actions={<ModeratorShowActions />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <EmailField source="email" />
          <NumberField source="weight" />
        </Tab>
        <Tab label="Votes">
          <ReferenceManyField
            reference="moderatorVote"
            source="id"
            target="moderatorId"
            perPage={25}
            sort={{}}
            pagination={<Pagination />}
          >
            <Datagrid rowClick={rowClick()}>
              <ReferenceField
                label="Proposal"
                source="proposalId"
                reference="restaurantProposition"
                target="id"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="vote" />
              <TextField source="result" />
              <NumberField source="weight" />
              <TextField source="comment" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ModeratorShow;
