// in ./ResetViewsButton.js
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  useRefresh,
  useNotify,
  fetchStart,
  fetchEnd,
} from "react-admin";
import { Flare } from "@material-ui/icons";
import { setMainPicture } from "../../service/setMainPicture.service";

const SetMainPictureButton = ({ record }) => {
  const restaurantPicture = record;
  const refresh = useRefresh();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClick = async (values) => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    await setMainPicture(restaurantPicture)
      .then(({ data }) => {
        refresh();
      })
      .catch((e) => {
        notify("Error: Picture not created", "warning", {}, false, 8000);
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <Button label="Set Main Picture" disabled={loading} onClick={handleClick}>
      <Flare />
    </Button>
  );
};

export default SetMainPictureButton;
