import memoize from "lodash/memoize";
import * as React from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  TextField,
  ReferenceField,
  BooleanField,
  sanitizeListRestProps,
  TopToolbar,
  Filter,
  SelectInput,
  useListContext,
  ExportButton,
} from "react-admin";
import RefreshStripeButton from "./actions/refreshStripe";

const AdvertisementBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
);

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, filterValues, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <RefreshStripeButton />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const choices = [
  { id: "active", name: "active" },
  { id: "canceled", name: "canceled" },
  { id: "trialing", name: "trialing" },
  { id: "unpaid", name: "unpaid" },
  { id: "pending_payment", name: "pending_payment" },
  { id: "incomplete", name: "incomplete" },
  { id: "incomplete_expired", name: "incomplete_expired" },
  { id: "past_due", name: "past_due" },
];

const AdvertisementFilter = (props) => (
  <Filter {...props}>
    <SelectInput label="Status" source="status" choices={choices} alwaysOn />
  </Filter>
);

const AdvertisementList = ({ ...props }) => (
  <List
    {...props}
    sort={{ field: "status", order: "DESC" }}
    perPage={25}
    bulkActionButtons={<AdvertisementBulkActionButtons />}
    filters={<AdvertisementFilter />}
    actions={<ListActions />}
  >
    <Datagrid rowClick={rowClick()} optimized>
      <ReferenceField
        label="City"
        source="cityId"
        reference="city"
        target="inseeCode"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Restaurant"
        source="restaurantId"
        reference="restaurant"
        target="id"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="position" />
      <BooleanField source="active" />
      <TextField source="status" />
    </Datagrid>
  </List>
);
export default AdvertisementList;
