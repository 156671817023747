import PhotoLibrary from "@material-ui/icons/PhotoLibrary";
import PictureList from "./pictureList";
import PictureShow from "./pictureShow";

const picture = {
  icon: PhotoLibrary,
  list: PictureList,
  show: PictureShow,
  options: { label: "Pictures" },
};

export default picture;
