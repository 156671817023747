// in ./ResetViewsButton.js
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  useNotify,
  fetchStart,
  fetchEnd,
  useRefresh,
} from "react-admin";
import { Refresh } from "@material-ui/icons";
import { refreshStripe } from "../../service/refreshStripe";

const RefreshStripeButton = ({ data }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    refreshStripe()
      .then(({ status }) => {
        if (status === 200) {
          notify(
            "Advertissement was successfully refreshed!",
            "info",
            {},
            false,
            5000
          );
          refresh();
        } else {
          notify(
            "Error: Advertissement not refreshed",
            "warning",
            {},
            false,
            8000
          );
        }
      })
      .catch((e) => {
        notify(
          "Error: Advertissement not refreshed",
          "warning",
          {},
          false,
          8000
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <Button
      label="Refresh Stripe data"
      disabled={loading}
      onClick={handleClick}
    >
      <Refresh />
    </Button>
  );
};

export default RefreshStripeButton;
