import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  fetchStart,
  fetchEnd,
  useRefresh,
  TextInput,
} from "react-admin";
import IconContentEdit from "@material-ui/icons/Edit";
import IconCancel from "@material-ui/icons/Cancel";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { changeSlug } from "../service/changeSlug.service";

function ChangeSlugButton({ onChange, record }) {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    values.restaurantId = record.id;

    try {
      const test = await changeSlug(values)
        .then(({ data }) => {
          setShowDialog(false);
          refresh();
        })
        .finally(() => {
          setLoading(false);
          dispatch(fetchEnd()); // stop the global loading indicator
        });

      console.log(test);
    } catch (error) {
      notify(error.toString(), "error", {}, false, 8000);
      console.log(error.toString());
    }
  };

  return (
    <>
      <Button onClick={handleClick} label="Modification du slug">
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit slug"
      >
        <DialogTitle>Edit slug</DialogTitle>

        <FormWithRedirect
          resource="restaurant"
          save={handleSubmit}
          initialValues={{ slug: record?.slug ?? "" }}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source="slug" />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default ChangeSlugButton;
