import memoize from "lodash/memoize";
import * as React from "react";
import { Fragment } from "react";
import { Children, cloneElement } from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  TextField,
  BooleanField,
  EditButton,
  DeleteButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  ExportButton,
  Filter,
  TextInput,
  BooleanInput,
  DateField,
  NumberField,
} from "react-admin";
import { simpleDateTimeOptions } from "../options/date.options";
import ValidatePropositionButton from "./bulkActions/acceptProposition";
import NotHalalPropositionButton from "./bulkActions/notHalalProposition";
import MyUrlField from "../components/customLink";

const RestaurantPropositionBulkActionButtons = (props) => (
  <Fragment>
    <NotHalalPropositionButton {...props} halal={false} done={true} />
    <ValidatePropositionButton {...props} validated />
    <ValidatePropositionButton {...props} validated={false} />
    <BulkDeleteWithConfirmButton {...props} />
  </Fragment>
);

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const RestaurantPropositionListActionToolbar = ({ children, ...props }) => {
  return (
    <div>{Children.map(children, (button) => cloneElement(button, props))}</div>
  );
};

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, filterValues, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const RestaurantPropositionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <BooleanInput label="Done" source="done" defaultValue={false} />
    <BooleanInput label="Validated" source="validated" defaultValue={false} />
  </Filter>
);

const RestaurantPropositionList = ({ ...props }) => (
  <List
    {...props}
    sort={{ field: "date", order: "DESC" }}
    perPage={25}
    bulkActionButtons={<RestaurantPropositionBulkActionButtons />}
    actions={<ListActions />}
    filters={<RestaurantPropositionFilter />}
    filterDefaultValues={{ done: false, validated: false }}
  >
    <Datagrid rowClick={rowClick()} optimized>
      <BooleanField source="validated" />
      <TextField source="name" />
      <MyUrlField source="certificateUrl" />
      <DateField {...simpleDateTimeOptions} source="date" />
      <NumberField source="weight" label="Poids" />
      <NumberField source="nbApprovalVotes" label="Votes Halal" />
      <NumberField source="nbRefuseVotes" label="Votes NON Halal" />
      <TextField source="proposalType" label="Type de propositions" />
      <BooleanField source="owner" />
      <BooleanField source="done" />
      <BooleanField source="halal" />
      <RestaurantPropositionListActionToolbar>
        <EditButton />
        <DeleteButton />
      </RestaurantPropositionListActionToolbar>
    </Datagrid>
  </List>
);
export default RestaurantPropositionList;
