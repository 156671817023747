import * as React from "react";
import {
  Tab,
  TabbedShowLayout,
  ImageField,
  Show,
  BooleanField,
  NumberField,
  ReferenceField,
  Datagrid,
  TextField,
  ReferenceManyField,
  TopToolbar,
} from "react-admin";
import EnablePictureButton from "./Actions/enablePicture";

const PictureShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EnablePictureButton data={data} />
  </TopToolbar>
);

const PictureShow = (props) => {
  return (
    <Show {...props} actions={<PictureShowActions />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <BooleanField source="active" />
          <ImageField
            source="dataFiles.standardResolution.url"
            label="Picture"
          />
          <NumberField source="UGCScore" />
          <ReferenceManyField
            label="Restaurant"
            reference="restaurantPicture"
            source="id"
            target="pictureId"
            sort={{ field: "pictureId", order: "ASC" }}
          >
            <Datagrid>
              <ReferenceField
                label="Restaurant"
                source="restaurantId"
                reference="restaurant"
                target="id"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default PictureShow;
