import memoize from "lodash/memoize";
import * as React from "react";
import { Fragment } from "react";
import {
  Datagrid,
  List,
  BooleanField,
  BulkDeleteWithConfirmButton,
  ImageField,
  NumberField,
} from "react-admin";

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const RestaurantBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteWithConfirmButton {...props} />
  </Fragment>
);

const RestaurantList = ({ ...props }) => (
  <List {...props} bulkActionButtons={<RestaurantBulkActionButtons />}>
    <Datagrid rowClick={rowClick()} optimized>
      <BooleanField source="active" />
      <ImageField source="dataFiles.standardResolution.url" label="Picture" />
      <NumberField source="UGCScore" />
    </Datagrid>
  </List>
);

export default RestaurantList;
