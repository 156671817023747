/* eslint react/jsx-key: off */
import PeopleIcon from "@material-ui/icons/People";
import memoize from "lodash/memoize";
import * as React from "react";
import { Fragment } from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  TextField,
  Filter,
  SearchInput,
  BulkExportButton,
} from "react-admin";

export const UserIcon = PeopleIcon;

const CustomDescriptionField = ({ record }) => {
  // "record" is a prop received from the Datagrid
  const str = record.description;
  return str ? (
    <span>{str.length > 200 ? str.slice(0, 200) + "..." : str}</span>
  ) : null;
};

const CityBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteWithConfirmButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
);

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const CityFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
);

const CityList = ({ ...props }) => (
  <List
    {...props}
    sort={{ field: "population", order: "DESC" }}
    filters={<CityFilter />}
    bulkActionButtons={<CityBulkActionButtons />}
  >
    <Datagrid
      rowClick={rowClick()}
      // expand={<CityEditEmbedded />}
      optimized
    >
      <TextField source="inseeCode" />
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="population" />
      <CustomDescriptionField />
    </Datagrid>
  </List>
);

export default CityList;
