/* eslint react/jsx-key: off */
import PeopleIcon from "@material-ui/icons/People";
import memoize from "lodash/memoize";
import { useMediaQuery } from "@material-ui/core";
import * as React from "react";
import { Children, Fragment, cloneElement } from "react";
import {
  Datagrid,
  List,
  SimpleList,
  TextField,
  BooleanField,
  ReferenceField,
  ChipField,
  Filter,
  SearchInput,
  BulkDeleteWithConfirmButton,
  BulkExportButton,
  EditButton,
  DeleteButton,
} from "react-admin";
import RefreshRestaurantsButton from "./bulkActions/refreshRestaurants";

export const UserIcon = PeopleIcon;

const RestaurantFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
);

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const RestaurantBulkActionButtons = (props) => (
  <Fragment>
    <RefreshRestaurantsButton {...props} />
    <BulkDeleteWithConfirmButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
);

const RestaurantListActionToolbar = ({ children, ...props }) => {
  return (
    <div>{Children.map(children, (button) => cloneElement(button, props))}</div>
  );
};

const RestaurantList = ({ ...props }) => (
  <List
    {...props}
    sort={{ field: "name", order: "ASC" }}
    filters={<RestaurantFilter />}
    bulkActionButtons={<RestaurantBulkActionButtons />}
  >
    {useMediaQuery((theme) => theme.breakpoints.down("sm")) ? (
      <SimpleList
        primaryText={(record) => record.name}
        secondaryText={(record) => record.role}
      />
    ) : (
      <Datagrid rowClick={rowClick()} optimized>
        <TextField source="id" />
        <BooleanField source="active" />
        <TextField source="name" />
        <TextField source="customName" />
        <TextField source="slug" />
        <ChipField source="businessStatus" />

        <ReferenceField
          label="City"
          source="cityId"
          reference="city"
          target="inseeCode"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <RestaurantListActionToolbar>
          <EditButton />
          <DeleteButton />
        </RestaurantListActionToolbar>
      </Datagrid>
    )}
  </List>
);

export default RestaurantList;
