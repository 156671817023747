export const getResourcePath = (resourceName) => {
  switch (resourceName) {
    case "restaurantProposition":
      return "restaurant/proposition";
    case "restaurantPicture":
      return "restaurant/picture";
    case "restaurantTag":
      return "restaurant/tag";
    case "moderatorVote":
      return "moderator/vote";
    default:
      return resourceName;
  }
};
