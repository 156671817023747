import RestaurantIcon from "@material-ui/icons/Restaurant";
import RestaurantList from "./restaurantList";
import RestaurantShow from "./restaurantShow";
import RestaurantEdit from "./restaurantEdit";

const restaurant = {
  list: RestaurantList,
  show: RestaurantShow,
  icon: RestaurantIcon,
  edit: RestaurantEdit,
};

export default restaurant;
