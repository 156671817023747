// in ./ResetViewsButton.js
import * as React from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import { ThumbUp, ThumbDown } from "@material-ui/icons";

const EnablePictureButton = ({ data, record }) => {
  const picture = data ?? record;
  const active = !picture.active;
  const id = picture.id;
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "picture",
    [id],
    { active },
    {
      onSuccess: () => {
        refresh();
        notify(`Pictures ${active ? "enabled" : "disabled"}`);
        unselectAll("posts");
      },
      onFailure: (error) => notify("Error: picture not updated", "warning"),
    }
  );

  const buttonLabel = `${active ? "Enable" : "Disable"} picture`;

  return (
    <Button label={buttonLabel} disabled={loading} onClick={updateMany}>
      {active ? <ThumbUp /> : <ThumbDown />}
    </Button>
  );
};

export default EnablePictureButton;
