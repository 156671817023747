import * as React from "react";
import memoize from "lodash/memoize";
import {
  Tab,
  TabbedShowLayout,
  TextField,
  BooleanField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ReferenceField,
  DateField,
  ChipField,
  UrlField,
  Show,
  ImageField,
  EditButton,
  TopToolbar,
} from "react-admin";
import { simpleDateOptions } from "../options/date.options";
import RefreshRestaurantButton from "./actions/refreshRestaurant";
import PictureQuickCreateButton from "../restaurantPicture/pictureQuickCreateButton";
import EnablePictureButton from "../Picture/Actions/enablePicture";
import SetMainPictureButton from "../restaurantPicture/Actions/setMainPicture";
import ChangeSlugButton from "./changeSlugButton";

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const RestaurantShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <RefreshRestaurantButton data={data} />
    <ChangeSlugButton label="change slug" record={data} />
  </TopToolbar>
);

const RestaurantShow = (props) => {
  return (
    <Show {...props} actions={<RestaurantShowActions />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="customName" />
          <TextField source="emailOwner" />
          <TextField source="promo" />
          <BooleanField source="active" />
          <BooleanField source="alcohol" />
          <BooleanField source="halal" />
          <TextField source="slug" />
          <ChipField source="businessStatus" />
          <UrlField source="customDeliveryLink" />
          <UrlField source="uberEatsLink" />
          <UrlField source="deliverooLink" />
          <UrlField source="justEatLink" />
          <UrlField source="instagramLink" />
          <UrlField source="facebookLink" />
          <TextField source="popularityRatio" />
          <DateField source="lastRefreshDate" />
          <ReferenceField
            label="City"
            source="cityId"
            reference="city"
            target="inseeCode"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        </Tab>
        <Tab label="Advertisements" path="advertisements">
          <ReferenceManyField
            reference="advertisement"
            source="id"
            target="restaurantId"
            perPage={25}
            sort={{}}
            pagination={<Pagination />}
          >
            <Datagrid rowClick={rowClick()}>
              <ReferenceField
                label="City"
                source="cityId"
                reference="city"
                target="inseeCode"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Restaurant"
                source="restaurantId"
                reference="restaurant"
                target="id"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <BooleanField source="active" />
              <DateField {...simpleDateOptions} source="start" />
              <DateField {...simpleDateOptions} source="end" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Pictures" path="pictures">
          <PictureQuickCreateButton />
          <ReferenceManyField
            label="Pictures"
            reference="restaurantPicture"
            source="id"
            target="restaurantId"
            perPage={25}
            sort={{ field: "pictureId", order: "ASC" }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <BooleanField source="principal" />
              <ReferenceField
                label="Active"
                source="pictureId"
                reference="picture"
                target="id"
                link="show"
              >
                <BooleanField source="active" />
              </ReferenceField>
              <ReferenceField
                label="Picture"
                source="pictureId"
                reference="picture"
                target="id"
                link="show"
              >
                <ImageField source="dataFiles.standardResolution.url" />
              </ReferenceField>
              <ReferenceField
                label="Picture"
                source="pictureId"
                reference="picture"
                target="id"
                link={false}
              >
                <EnablePictureButton />
              </ReferenceField>
              <SetMainPictureButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Tags" path="tags">
          <ReferenceManyField
            label="Tags"
            reference="restaurantTag"
            source="id"
            target="restaurantId"
            perPage={25}
            sort={{ field: "tagId", order: "ASC" }}
            link={false}
            pagination={<Pagination />}
          >
            <Datagrid>
              <BooleanField source="main" />
              <ReferenceField
                label="Tags"
                source="tagId"
                reference="tag"
                target="id"
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default RestaurantShow;
