const authProvider = {
  login: ({ password }) => {
    localStorage.setItem("apiKey", password);
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("apiKey");
    return Promise.resolve();
  },

  checkAuth: () => {
    return localStorage.getItem("apiKey")
      ? Promise.resolve()
      : Promise.reject();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 403) {
      localStorage.removeItem("apiKey");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    return localStorage.getItem("apiKey")
      ? Promise.resolve()
      : Promise.reject();
  },
};

export default authProvider;
