import LocationCityIcon from "@material-ui/icons/LocationCity";
import CityList from "./cityList";
import CityShow from "./cityShow";
import CityEdit from "./cityEdit";

const city = {
  list: CityList,
  //   create: UserCreate,
  edit: CityEdit,
  show: CityShow,
  icon: LocationCityIcon,
};

export default city;
