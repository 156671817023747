import { stringify } from "querystring";
import { fetchUtils } from "react-admin";
import { apiUrl } from "../App";

export const refreshRestaurant = (ids) => {
  const httpClient = (url, options) => {
    options = options || {};
    options.headers = new Headers({ Accept: "application/json" });
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      options.headers.set("admin-api-key", `${apiKey}`);
    }

    return fetchUtils.fetchJson(url, options);
  };
  const query = {
    manyFilter: JSON.stringify({ ids }),
  };

  return httpClient(`${apiUrl}/restaurant/refresh?${stringify(query)}`, {
    method: "PUT",
    params: query,
  });
};
