import { fetchUtils } from "react-admin";
import { apiUrl } from "../App";

export const addPicture = async (values) => {
  const httpClient = (url, options) => {
    options = options || {};
    options.headers = new Headers({
      Accept: "application/json",
    });
    const apiKey = localStorage.getItem("apiKey");
    if (apiKey) {
      options.headers.set("admin-api-key", `${apiKey}`);
    }

    return fetchUtils.fetchJson(url, options);
  };

  const formData = new FormData();
  formData.append("picture", values.picture.rawFile);
  formData.append("restaurantId", values.restaurantId);
  formData.append("principal", values.principal);

  return httpClient(`${apiUrl}/picture/new`, {
    method: "PUT",
    body: formData,
  });
};
