import AddCircle from "@material-ui/icons/AddCircle";
import restaurantPropositionList from "./restaurantPropositionList";
import restaurantPropositionShow from "./restaurantPropositionShow";
import restaurantPropositionEdit from "./restaurantPropositionEdit";

const restaurantProposition = {
  list: restaurantPropositionList,
  show: restaurantPropositionShow,
  edit: restaurantPropositionEdit,
  icon: AddCircle,
  options: { label: "Restaurant Proposition" },
};

export default restaurantProposition;
