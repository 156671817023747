import * as React from "react";
import {
  Tab,
  TabbedShowLayout,
  TextField,
  EmailField,
  DateField,
  BooleanField,
  UrlField,
  ArrayField,
  ChipField,
  SingleFieldList,
  Show,
  Datagrid,
  NumberField,
  ReferenceField,
} from "react-admin";
import { simpleDateTimeOptions } from "../options/date.options";
import MyUrlField from "../components/customLink";

const RestaurantPropositionShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="name" />
          <MyUrlField source="certificateUrl" />
          <EmailField source="email" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="halalComment" />
          <BooleanField source="validated" />
          <TextField source="proposalType" label="Type de propositions" />
          <BooleanField source="owner" />
          <DateField {...simpleDateTimeOptions} source="date" />
          <BooleanField source="done" />
          <BooleanField source="restaurantData.alcohol" label="Alcohol" />
          <TextField source="ip" />
          <TextField source="location" />
          <TextField source="cityName" />
          <TextField source="country" />
          <UrlField
            source="restaurantData.customDeliveryLink"
            label="Custom delivery link"
          />
          <UrlField
            source="restaurantData.deliverooLink"
            label="Deliveroo link"
          />
          <UrlField source="restaurantData.justEatLink" label="JustEat link" />
          <UrlField
            source="restaurantData.uberEatsLink"
            label="UberEats link"
          />
          <UrlField
            source="restaurantData.facebookLink"
            label="Facebook link"
          />
          <UrlField
            source="restaurantData.instagramLink"
            label="Instagram link"
          />
          <ArrayField source="restaurantData.tags" label="Tags">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        </Tab>
        <Tab label="Liste des votes">
          <ArrayField source="moderatorVotes" label="Liste des votes">
            <Datagrid>
              <ReferenceField
                label="Moderator"
                source="moderatorId"
                reference="moderator"
                target="id"
                link="show"
              >
                <TextField source="email" />
              </ReferenceField>
              <DateField source="date" />
              <TextField source="vote" />
              <NumberField source="weight" />
              <TextField source="comment" />
              <TextField source="result" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default RestaurantPropositionShow;
