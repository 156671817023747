import * as React from "react";
import memoize from "lodash/memoize";
import {
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  ReferenceField,
  BooleanField,
  DateField,
  Show,
  TopToolbar,
  EditButton,
} from "react-admin";
import { simpleDateOptions } from "../options/date.options";

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const CityShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CityShow = (props) => {
  return (
    <Show {...props} actions={<CityShowActions />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="inseeCode" />
          <TextField source="name" />
          <TextField source="slug" />
          <NumberField source="population" />
          <TextField source="description" />
        </Tab>
        <Tab label="Restaurants">
          <ReferenceManyField
            reference="restaurant"
            source="inseeCode"
            target="cityId"
            perPage={50}
            pagination={<Pagination />}
          >
            <Datagrid rowClick={rowClick()}>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="slug" />
              <TextField source="cityId" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Advertisements">
          <ReferenceManyField
            reference="advertisement"
            source="inseeCode"
            target="cityId"
            perPage={50}
            sort={{}}
            pagination={<Pagination />}
          >
            <Datagrid rowClick={rowClick()}>
              <ReferenceField
                label="City"
                source="cityId"
                reference="city"
                target="inseeCode"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Restaurant"
                source="restaurantId"
                reference="restaurant"
                target="id"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <BooleanField source="active" />
              <DateField {...simpleDateOptions} source="start" />
              <DateField {...simpleDateOptions} source="end" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CityShow;
