import * as React from "react";
import {
  Tab,
  TabbedShowLayout,
  TextField,
  Show,
  TopToolbar,
  EditButton,
} from "react-admin";

const CityShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CityShow = (props) => {
  return (
    <Show {...props} actions={<CityShowActions />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="emoji" />
          <TextField source="description" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CityShow;
