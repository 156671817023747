import memoize from "lodash/memoize";
import * as React from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  EmailField,
  NumberField,
} from "react-admin";

const AdvertisementBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
);

const rowClick = memoize(() => (id, basePath, record) => {
  return "show";
});

const ModeratorList = ({ ...props }) => (
  <List
    {...props}
    perPage={25}
    bulkActionButtons={<AdvertisementBulkActionButtons />}
  >
    <Datagrid rowClick={rowClick()} optimized>
      <EmailField source="email" />
      <NumberField source="nbApprovalVotes" label="Votes Halal" />
      <NumberField source="nbRefuseVotes" label="Votes NON Halal" />
      <NumberField source="weight" />
    </Datagrid>
  </List>
);
export default ModeratorList;
