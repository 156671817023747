import React from "react";
import { Admin, Resource } from "react-admin";
import advertisement from "./advertisement";
import restaurantProposition from "./restaurantProposition";
import moderator from "./moderator";
import city from "./city";

import myDataProvider from "./providers/dataProvider";
import authProvider from "./providers/authProvider";
import restaurant from "./restaurant";
import picture from "./Picture";
import restaurantPicture from "./restaurantPicture";
import tag from "./tag";

export const apiUrl = process.env.REACT_APP_API_URL || 'https://mon-resto-halal.com/api/admin';
const dataProvider = myDataProvider(apiUrl);

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    <Resource name="restaurant" {...restaurant} />
    <Resource name="city" {...city} />
    <Resource name="tag" {...tag} />
    <Resource name="advertisement" {...advertisement} />
    <Resource name="restaurantProposition" {...restaurantProposition} />
    <Resource name="moderator" {...moderator} />
    <Resource name="restaurantPicture" {...restaurantPicture} />
    <Resource name="restaurantTag" />
    <Resource name="moderatorVote" />
    <Resource name="picture" {...picture} />
  </Admin>
);

export default App;
