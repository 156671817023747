import * as React from "react";
import {
  Tab,
  TabbedShowLayout,
  TextField,
  ReferenceField,
  BooleanField,
  Show,
} from "react-admin";

const AdvertisementShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <ReferenceField
            label="City"
            source="cityId"
            reference="city"
            target="inseeCode"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Restaurant"
            source="restaurantId"
            reference="restaurant"
            target="id"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="active" />
          <TextField source="position" />
          <BooleanField source="active" />
          <TextField source="status" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AdvertisementShow;
