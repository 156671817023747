import * as React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

const CityEdit = ({ permissions, ...props }) => (
  <Edit title="Edit City" {...props}>
    <SimpleForm warnWhenUnsavedChanges redirect="show">
      <TextInput source="description" multiline fullWidth />
      <TextInput source="emoji" multiline fullWidth />
    </SimpleForm>
  </Edit>
);

export default CityEdit;
