// in ./ResetViewsButton.js
import * as React from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import { DeleteForever, CheckCircle } from "@material-ui/icons";

const NotHalalPropositionButton = ({
  selectedIds,
  halal = false,
  done = true,
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "restaurantProposition",
    selectedIds,
    { done, halal },
    {
      onSuccess: () => {
        refresh();
        notify(`Propositions set to ${halal ? "halal" : "not halal"}`);
        unselectAll("restaurantProposition");
      },
      onFailure: (error) =>
        notify("Error: propositions not updated", "warning"),
    }
  );

  const buttonLabel = `${halal ? "Halal" : "Not Halal"} propositions`;

  return (
    <Button label={buttonLabel} disabled={loading} onClick={updateMany}>
      {halal ? <CheckCircle /> : <DeleteForever />}
    </Button>
  );
};

export default NotHalalPropositionButton;
