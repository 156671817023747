export const simpleDateOptions = {
  locales: "fr-FR",
  options: {
    year: "2-digit",
    month: "short",
    day: "2-digit",
  },
};

export const simpleDateTimeOptions = {
  locales: "fr-FR",
  options: {
    year: "2-digit",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
};

export const completeDateOptions = {
  locales: "fr-FR",
  options: {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  },
};

export const completeDateTimeOptions = {
  locales: "fr-FR",
  options: {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
};
