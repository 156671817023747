import LabelIcon from "@material-ui/icons/Label";
import TagList from "./tagList";
import TagShow from "./tagShow";
import TagEdit from "./tagEdit";

const tag = {
  list: TagList,
  //   create: UserCreate,
  edit: TagEdit,
  show: TagShow,
  icon: LabelIcon,
};

export default tag;
